/* Import font */
@font-face {
  font-family: 'Chesalova';
  src: url('../assets/fonts/ChesalovaRegular-PV75m.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.navbar-vertical {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row; /* Horizontal alignment */
  justify-content: space-around; /* Evenly space items with flexible spacing */
  padding: 10px 5%; /* Add flexible padding on the sides */
  font-family: 'Chesalova', 'Georgia', serif;
  font-size: 18px;
  background-color: #f8f8f8; /* Light gray background */
  z-index: 1000; /* Ensure the navbar stays on top */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-around; /* Distribute space around items */
}

.navbar-item {
  margin: 0; /* Remove margin as justify-content handles spacing */
}

.navbar-item a {
  color: #b01e1f;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
}

.navbar-item a:hover {
  text-decoration: underline;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Lower than the ToastContainer's z-index */
    /* Other styles */
}