/* Import font */
@font-face {
    font-family: 'aller';
    src: url('/src/assets/fonts/Aller_Std_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .home-section-4 {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #b94141;
    background-color: #ffffff;
    padding: 50px 20px;
  }

  .section-title {
    font-family: 'aller', 'Georgia', serif;
    font-size: 38px; /* Ensures the title is prominent */
    margin-bottom: 30px; /* Adds space below the title */
    color: #b94141;
  }

.events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between each event card */
}

.event-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 700px;
    text-align: left;
    position: relative;
}

.event-theme {
    font-size: 1.5rem;
    color: #b94141;
    margin-bottom: 10px;
}

.event-time, .event-location, .event-precautions {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 8px;
}

.event-card::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 10px;
    width: 6px;
    height: 80%;
    background-color: #b94141;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .event-card {
        width: 90%;
    }

    .event-card::before {
        left: -10px;
    }
}

.wedding-image {
    width: 100%;
    max-width: 800px; /* 控制图片的最大宽度 */
    height: auto;
    margin: 20px auto; /* 居中对齐 */
    display: block;
    border-radius: 8px; /* 可选：添加圆角 */
}

.dresscode-image {
  max-width: 100%; /* Ensures the image does not exceed the container's width */
  height: auto;    /* Maintains the aspect ratio */
  display: block;  /* Ensures the image is a block element */
  margin: 10px auto; /* Centers the image and adds some vertical spacing */
  max-height: 300px; /* Sets a maximum height for the image */
  object-fit: contain; /* Ensures the image is scaled to fit within the max dimensions */
}