/* Import font */
@font-face {
    font-family: 'Chesalova';
    src: url('../../assets/fonts/ChesalovaRegular-PV75m.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.rsvp-form {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: transparent; /* Updated to match the background in the picture */
    border-radius: 0px; /* Removed the border-radius */
    box-shadow: none; /* Removed the box-shadow */
    font-family: 'Chesalova', serif; /* Updated font-family to match the style in the picture */
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}


.rsvp-form h2 {
    text-align: left; /* Left-align the heading */
    color: #a88a57; /* Updated color to match the gold text in the picture */
    font-size: 2em; /* Increased font size */
    font-family: 'Chesalova', serif; /* Updated to match the font style */
    margin-bottom: 20px;
}

.rsvp-form label {
    display: block;
    margin-bottom: 10px;
    color: black;
    font-size: 1.2em; /* Increased font size */
}

.rsvp-form .required {
    color: #a88a57; /* Updated the required symbol color */
    margin-left: 5px;
    margin-bottom: 10px;
}

.rsvp-form input,
.rsvp-form select,
.rsvp-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid black; /* Updated border color to match the style */
    border-radius: 0px; /* Removed border-radius for a more rectangular look */
    font-size: 0.9em; /* Increased font size */
    background-color: transparent; /* Lightened the input background color */
}

.rsvp-form textarea {
    resize: vertical;
    min-height: 100px;
}

.rsvp-form button {
    width: 100%;
    padding: 12px;
    background-color: #a88a57; /* Updated to match the button color in the picture */
    color: white;
    border: none;
    border-radius: 0px; /* Removed border-radius */
    font-size: 1em;
    font-family: 'arial', serif; /* Updated to match the font style */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.rsvp-form button:hover {
    background-color: #8c6f47; /* Darkened hover state to match style */
}