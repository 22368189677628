.content-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .ourstory-page {
    text-align: center;
    color: #b01e1f;
    font-family: 'Chesalova', 'Georgia', serif;
  }
  
  .story-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .story-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .story-item:hover {
    transform: translateY(-5px);
  }
  
  .story-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .story-item p {
    padding: 15px;
    font-size: 16px;
    color: #333;
  }