.rsvp-page {
    display: flex;
    flex-direction: column; /* 垂直排列子元素 */
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
    min-height: 100vh; /* 占满整个视口高度 */
    padding: 20px;
    background-color: #f9f9f9; /* 示例背景色 */
  }

.rsvp-section-1,
.rsvp-section-2 {
  width: 90%; /* 使用百分比宽度以适应不同屏幕 */
  max-width: 600px; /* 限制最大宽度 */
  margin-bottom: 20px; /* 间距 */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* 添加过渡效果 */
}

.rsvp-section-1 {
  margin-bottom: 10px; /* 减少底部间距 */
}

.rsvp-section-2 {
  margin-top: 10px; /* 增加顶部间距 */
  margin-bottom: 0; /* 去掉最后一个元素的底部间距 */
}

.rsvp-section-1:hover,
.rsvp-section-2:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* 增强悬停时的阴影 */
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .rsvp-section-1,
  .rsvp-section-2 {
    width: 80%; /* 在较大屏幕上增加宽度 */
    max-width: 800px; /* 增加最大宽度 */
  }
}

/* Adjust RSVPForm styles if needed */
.rsvp-form {
    margin: 0;
}
