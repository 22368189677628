.content-container {
    padding-top: 60px; /* Adjust based on your Navbar height */
    padding-left: 2%;
    padding-right: 2%;
}

.gallery-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: calc(100vh - 60px); /* Adjust based on your Navbar height */
    overflow-y: auto;
}

.album-selector {
    display: flex;
    margin-bottom: 20px;
}

.album-button {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.album-button:hover {
    background-color: #e0e0e0;
}

.album-button.active {
    background-color: #007bff;
    color: #fff;
}

.carousel {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 800px;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
}

.prev {
    left: -40px;
}

.next {
    right: -40px;
}

.album-row {
    display: flex;
    flex-direction: column;
    height: 40vh;
    color: #b01e1f;
    font-family: 'Chesalova', 'Georgia', serif;
}

.album-row h2 {
    margin-bottom: 0.5rem;
    color: #b01e1f;
    font-family: 'Chesalova', 'Georgia', serif;
}

.image-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
    padding: 1rem 0;
    height: calc(40vh - 2rem); /* Subtracting space for the title */
}

.gallery-image {
    height: 100%;
    width: auto;
    object-fit: cover;
    cursor: pointer;
}

/* Optional: Hide scrollbar for cleaner look */
.image-scroll-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.image-scroll-container::-webkit-scrollbar {
    display: none;
}

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.close {
    position: absolute;
    top: -40px;
    right: -40px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}