.home-sections-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.home-sections-row > * {
    flex: 1;
    margin: 0 0px; /* Optional: add some space between sections */
    box-sizing: border-box; /* Ensure padding/margin don't affect width */
}