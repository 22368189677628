/* Import font */
@font-face {
    font-family: 'aller';
    src: url('../../assets/fonts/Aller_Std_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .home-section2 {
    background-color: #A52A2A;
    color: white;
    padding: 50px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.home-section2 h1 {
    font-family: 'aller', 'Georgia', serif;
    font-size: 38px; /* Match Section2 headline size */
    margin-bottom: 20px;
}

.image-container2 {
    margin-bottom: 20px;
    position: relative; /* Ensure the container can be used as a reference for absolute positioning */
    width: 100%; /* Ensure the container takes full width */
}

.couple-image2 {
    width: 100%;
    max-width: 600px; /* Consistent max-width with Section2 */
    height: 100%;
    border-radius: 8px;
}
.home-section2 p {
    font-size: 1.2rem; /* Adjusted to match Section2 */
    color: white;
    line-height: 1.6; /* Match Section2 line-height */
}