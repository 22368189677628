/* Import font */
@font-face {
    font-family: 'aller';
    src: url('../../assets/fonts/Aller_Std_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .home-section1 {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #b94141;
    background-color: #f9ecec;
    padding: 50px 20px;
}

.home-section1 h1 {
    font-family: 'aller', 'Georgia', serif;
    font-size: 38px; /* Match Section2 headline size */
    margin-bottom: 20px;
}

.image-container {
    margin-bottom: 20px;
}

.couple-image {
    width: 100%;
    max-width: 600px; /* Consistent max-width with Section2 */
    height: 100%;
    border-radius: 8px;
}

.home-section1 p {
    font-size: 1.2rem; /* Adjusted to match Section2 */
    color: #b94141;
    line-height: 1.6; /* Match Section2 line-height */
}